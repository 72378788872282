
import React from 'react'
import {mdx} from '@mdx-js/react'

/* @jsxRuntime classic */
/* @jsx mdx */
/* @jsxFrag mdx.Fragment */
import withLayout from 'nextra-theme-blog';
import {withSSG} from 'nextra/ssg';
import layoutConfig from '/vercel/path0/theme.config.js';
import $NextImageNextra from "next/image";
const MDXLayout = function NextraPage(props) {
  return withSSG(withLayout({
    filename: "index.mdx",
    route: "/",
    meta: {
      "type": "posts",
      "title": "KY4QX Blog",
      "date": "2021-03-19T00:00:00.000Z"
    },
    pageMap: [{
      "name": "index",
      "route": "/",
      "frontMatter": {
        "type": "posts",
        "title": "KY4QX Blog",
        "date": "2021-03-19T00:00:00.000Z"
      }
    }, {
      "name": "posts",
      "children": [{
        "name": "my-pi-star-hotspot-setup",
        "route": "/posts/my-pi-star-hotspot-setup",
        "frontMatter": {
          "title": "My Pi-Star Hotspot Setup",
          "date": "2022/08/30",
          "description": "My Pi-Star Hotspot Setup",
          "tag": "digital",
          "author": "Michael Rupert"
        }
      }, {
        "name": "pota-summary",
        "route": "/posts/pota-summary",
        "frontMatter": {
          "title": "Embeddable POTA Summary",
          "date": "2022/09/07",
          "description": "How to use my Embeddable POTA Summary",
          "tag": "web",
          "author": "Michael Rupert"
        }
      }],
      "route": "/posts"
    }, {
      "name": "tags",
      "children": [{
        "name": "[tag]",
        "route": "/tags/[tag]",
        "frontMatter": {
          "type": "tag",
          "title": "Tagged Posts"
        }
      }],
      "route": "/tags"
    }]
  }, layoutConfig))(props);
};
function MDXContent({components, ...props}) {
  return <MDXLayout components={components} {...props}><h1>{"KY4QX"}</h1></MDXLayout>;
}
MDXContent.isMDXComponent = true;
export default MDXContent;
